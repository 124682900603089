export default {
	props: {
		rules: {
			type: [String, Object],
			default: ''
		},
		fieldName: {
			type: String,
			default: ''
		},
		validationMode: {
			type: String,
			default: 'eager'
		},
		customMessages: {
			type: Object,
			default: () => ({})
		},
		validateField: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		hasRules () {
			return typeof this.rules === 'string'
				? !!this.rules.length
				: !!Object.keys(this.rules).length
		}
	}
}
